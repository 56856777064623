import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

const Carousel = ({
  slides,
  customStyle,
  customBreakpoints,
}: {
  slides: React.ReactNode[];
  customStyle?: React.CSSProperties;
  customBreakpoints?: Record<number, { slidesPerView: number }>;
}): JSX.Element => {
  const baseStyle = {
    zIndex: 0,
  };
  const baseBreakpoints = {
    768: {
      slidesPerView: 3,
    },
  };

  const componentStyle = { ...baseStyle, ...customStyle };
  const componentBreakpoints = customBreakpoints || baseBreakpoints;

  return (
    <Swiper
      slidesPerView={1}
      breakpoints={componentBreakpoints}
      style={componentStyle}
      height={100}
      spaceBetween={30}
      loop
      modules={[Autoplay]}
      autoplay={{
        delay: 1500,
        disableOnInteraction: true,
      }}
      grabCursor
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={index}>{slide}</SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Carousel;
